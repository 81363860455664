import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xl-6 offset-xl-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_community_gallery_image_form = _resolveComponent("community-gallery-image-form")!
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_content_card, null, {
          body: _withCtx(() => [
            (_ctx.model?.id !== undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.model.id > 0
                                        ? _ctx.model.name ||
                                          `Community Image ${_ctx.model.id}`
                                        : "New Community Image"), 1),
                  _createVNode(_component_community_gallery_image_form, { "community-gallery-image": _ctx.model }, null, 8, ["community-gallery-image"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_loading_indicator)
                ]))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}