
import { defineComponent, onMounted, ref } from "vue";
import ContentCard from "@/components/ContentCard.vue";
import CommunityGalleryImageForm from "@/components/CommunityGalleryImageForm.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { routeNames } from "@/router";
import { CommunityGalleryImageModel } from "@/models";
import { restClient } from "@/store/actions";

export default defineComponent({
    components: {
        "content-card": ContentCard,
        "community-gallery-image-form": CommunityGalleryImageForm,
        "loading-indicator": LoadingIndicator
    },
    props: {
        communityGalleryImageId: { type: Number, required: true }
    },
    setup(props) {
        const model = ref<CommunityGalleryImageModel>({});

        onMounted(async () => {
            if (props.communityGalleryImageId === 0) {
                model.value = {
                    id: 0,
                    sortOrder: null,
                    imageUrl: ""
                };
            } else {
                const response =
                    await restClient.getJson<CommunityGalleryImageModel>(
                        `/api/admin/CommunityGalleryImage/${props.communityGalleryImageId}`
                    );

                if (response.data) {
                    model.value = response.data;
                }
            }
        });

        return {
            routeNames,
            model
        };
    }
});
